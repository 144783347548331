import { defineComponent, ref } from 'vue';
var refIframe = ref();
export default defineComponent({
    props: {
        idYoutube: {
            type: String,
            required: true,
            default: 'GXqJMr_T7cw'
        }
    },
    setup: function (props) {
        var playVideo = function (observer) {
            if (observer.isVisible) {
                // const isSp = window.innerWidth < 768
                var iframe = refIframe.value;
                iframe.src = "https://www.youtube.com/embed/" + props.idYoutube + "?autoplay=0&rel=0&showinfo=0&mute=" + 0 + "&iv_load_policy=3";
            }
        };
        return { playVideo: playVideo, refIframe: refIframe };
    }
});
