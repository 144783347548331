import { defineComponent } from 'vue';
var releaseList = [
    { link: 'https://dena.com/jp/news/4641/', text: '2020年' },
    { link: 'https://dena.com/jp/news/4806/', text: '2021年' },
    { link: 'https://dena.com/jp/news/4912/', text: '2022年' },
    { link: 'https://dena.com/jp/news/5057/', text: '2023年' },
    { link: 'https://dena.com/jp/news/5122/', text: '2024年7月' },
    { link: 'https://dena.com/jp/news/5136/', text: '2024年9月' },
    { link: 'https://dena.com/jp/news/5167/', text: '2024年11月' }
];
export default defineComponent({
    data: function () {
        return {
            isClose: true,
            releaseList: releaseList
        };
    }
});
