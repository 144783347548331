import { render, staticRenderFns } from "./about.vue?vue&type=template&id=3a46ffa3&scoped=true&"
import script from "./about.vue?vue&type=script&lang=ts&"
export * from "./about.vue?vue&type=script&lang=ts&"
import style0 from "./about.vue?vue&type=style&index=0&id=3a46ffa3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a46ffa3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/section-title.vue').default,CommonCustomImage: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/custom-image.vue').default,TopVideos: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/videos/index.vue').default,TopAccess: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/access.vue').default})
